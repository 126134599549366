.skill-card {
    margin-left: 10px;
    margin-bottom: 10px;
    max-width: 300px;
    width: 300px;
}

.skill-card.card {
    height: 450px;
}

.card-footer-badge {
    font-size: 1rem;
}

.card-deck {
    justify-content: center;
}